<template>
  <v-container class="mt-4 ml-2">
    <v-row>
      <v-col cols="12">
	      <v-card class="shadowCard">
	      	<v-card-title primary-title class="text-subtitle-1">
		        Configurar grupos
		        <v-spacer></v-spacer>
		        <v-btn color="primary" @click="getGrupos()" small class="mr-2">Consultar</v-btn>
	      	</v-card-title>
	        <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
	        	<!-- Menu superior de tabs -->

		        <v-tabs
		        	v-if="grupos"
			        dense
			        class="mt-4"
			        v-model="tab"
			        background-color="white"
			      >
			        <v-tabs-slider color="primary"></v-tabs-slider>

			        <v-tab
			          v-for="(item,i) in items"
			          :key="i"
			        >
			          <v-icon v-text="item.icon" left></v-icon>{{ item.name }}
			        </v-tab>


			        <!-- Apartado de las secciones de los tabs -->
			        <v-tabs-items v-model="tab">
			          <v-tab-item>
		              <v-card class="elevation-0 mt-4">

		              	<v-row justify="end">
		              		<v-col cols="12" md="6">
	                      <v-text-field
	                        v-model="searchFast"
	                        label="Buscar"
	                        append-icon="mdi-magnify"
	                        filled
	                        dense
	                        hide-details
	                      ></v-text-field>
		              		</v-col>
		              	</v-row>


		                <v-card-text>	
		                	<v-data-table
										    dense
										    :headers="headers"
										    :items="grupos.gruposFast"
										    item-key="id"
										    class="elevation-0"
                        :search="searchFast"
										  >
                        <template v-slot:item.optimizado="{ item }">
                          <v-switch v-model="item.optimizado" @change="editar( item )"></v-switch>
                        </template>

                        <template v-slot:item.deleted="{ item }">
                          <v-switch v-model="item.deleted" @change="editar( item )"></v-switch>
                        </template>     

                        <template v-slot:item.online="{ item }">
                          <v-switch v-model="item.online" @change="editar( item )"></v-switch>
                        </template>     
                      </v-data-table>
		                </v-card-text>	
		              </v-card>
			          </v-tab-item>
			        

			          <!-- horarios de inbi -->
			          <v-tab-item>
			            <v-col cols="12" lg="12">
			              <v-card class="elevation-0">
			                <v-card-title primary-title class="pt-0 ">
                        <v-text-field
                          v-model="searchInbi"
                          label="Buscar"
                          append-icon="mdi-magnify"
                          filled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-card-title>

			                <v-card-text >
			                  <v-data-table
											    dense
											    :headers="headers"
											    :items="grupos.gruposInbi"
											    item-key="id"
											    class="elevation-0"
                          :search="searchInbi"
											  >
                          <template v-slot:item.optimizado="{ item }">
                            <v-switch v-model="item.optimizado" @change="editar( item )"></v-switch>
                          </template>

                          <template v-slot:item.deleted="{ item }">
                            <v-switch v-model="item.deleted" @change="editar( item )"></v-switch>
                          </template> 

                          <template v-slot:item.online="{ item }">
                            <v-switch v-model="item.online" @change="editar( item )"></v-switch>
                          </template>    
                        </v-data-table>
			                </v-card-text>	
			              </v-card>
			            </v-col>
			          </v-tab-item>

			        </v-tabs-items>
			      </v-tabs>
	        </v-card-text>
	      </v-card>		
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	row:null,
    	tab: {name:'Grupos FAST'   , icon:'mdi-file' }, 
      items: [
        {name:'Grupos FAST'    , icon:'mdi-file' }, 
        {name:'Grupos INBI'    , icon:'mdi-file' }, 
      ],

      headers: [
        { text: 'id'          , value: 'id' },
        { text: 'Nombre'      , value: 'nombre' },
        { text: 'Ciclo'		    , value: 'ciclo' },
        { text: 'Curso'       , value: 'curso' },
        { text: 'Nivel'       , value: 'id_nivel' },
        { text: 'Hora inicio' , value: 'hora_inicio' },
        { text: 'Hora fin'  	, value: 'hora_fin' },
        { text: 'iderp'       , value: 'iderp' },
        { text: 'Optimizado'  , value: 'optimizado' },
        { text: 'Eliminado'   , value: 'deleted' },
        { text: 'Online'      , value: 'online' },
      ],

      grupos:[],
      dialogError: false,
			dialogSuccess:false,
			error:'',
			successMensaje:'',
			overlay:true,
			horarios:null,
			cicloInf: 		null,
      ciclos:  			[],

      searchFast:'',
      searchInbi:''
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          this.getGrupos()
        }
      },
    },

   
    created () {
      this.initialize()
    },

    methods: {
    	initialize () {
      	this.cargar = true
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGrupos () {
      	if(this.cicloInf){
	      	// vaciamos el horario
	      	this.grupos = null
	      	// Activamos el loader
	    		this.cargar = true
	    		// Hacemos la peticios
	    		this.$http.get(`lms.catalogos.grupos.all/${ this.cicloInf.id_ciclo }/${ this.cicloInf.id_ciclo_relacionado }`).then(response=>{
	    			// Guardamos la respuesta
	          this.grupos = response.data
	          // Quitamos el loader
	          this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
      	}else{
      		this.validarErrorDirecto('Favor de seleccionar un ciclo')
      	}
      },

      editar ( item ){
        // Actualización del grupo, editado y eliminado
        this.$http.put(`lms.catalogos.grupos.upudate/${ item.id }`, item).then(response=>{
          // Guardamos la respuesta
          // this.getGrupos()
          // Quitamos el loader
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })     
      }

    },

    computed:{
    	...mapGetters('login',['getdatosUsuario'])
    }
  }
</script>

<style>
	.v-snack__content{
		padding: 0px !important;
	}
</style>